import { Outlet } from "react-router-dom";
import './index.scss';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames";
import HeaderBar from "./headerBar";
import LeftMenu from "./leftMenu";

function Layout() {

  const navigate = useNavigate();
  
  // open or close menu
  const [menuFlag, setMenuFlag] = useState(true)
  const changeMenuStatus = () => {
    setMenuFlag(!menuFlag)
  }

  const changeTab = (type) => {
    setCurTab(type);
    navigate(type)
  }

  return (
    <div className="box" style={{ overflow: 'hidden' }}>
      <div>
        <div className="headerBar">
          <HeaderBar
            changeMenuStatus={changeMenuStatus}
            menuFlag={menuFlag}
          />
        </div>
        <div className="bodyMain">
          <div className="menu" style={{ width: menuFlag ? '17%' : '0' }}>
            <LeftMenu 
            />
          </div>
          <div className="content" style={{ width: menuFlag ? '83%' : '100%' }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>

  )
}

export default Layout;