import { createSlice } from "@reduxjs/toolkit";
import { getAction } from "@/api/manage"
import { setToken } from "@/utils/token";

const userStore = createSlice({
  name: 'user',
  initialState:{
    selectFlag: '/dashboard',
    userName: '',
  },
  reducers:{
    setSelectFlag(state, action){
      state.selectFlag = action.payload
    },
    setUserName(state, action){
      state.userName = action.payload
    }
  }
})

const {setSelectFlag, setUserName} = userStore.actions;
export {setSelectFlag, setUserName}
const userReducer = userStore.reducer;
export default userReducer;