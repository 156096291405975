import { useState, useEffect } from "react";
import './leftMenu.scss';
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from "react-redux";

function LeftMenu(props) {
  
  const {selectFlag} = useSelector(state => state.user);

  // menu list
  const [menuItems, setMenuItems] = useState([
    {name: 'ホーム', link: '/dashboard', selectColor: selectFlag === '/dashboard' ? '#EAF6FD' : '#FFFFFF'},
    // {name: 'テスト', link: '/test', selectColor: selectFlag === '/test' ? '#EAF6FD' : '#FFFFFF'},
  ])

  useEffect(() => {
    menuItems.map(element => {
          if (element.link === selectFlag) {
              element.selectColor = '#EAF6FD'
          } else {
              element.selectColor = '#FFFFFF'
          }
      })
      setMenuItems([...menuItems])
  }, [selectFlag])

  const changeMenu = (item, index) => {
    if (item.selectColor === '#EAF6FD') {
      return
    } else {
      menuItems.map(element => {
          element.selectColor = '#FFFFFF'
      })
      for (var i = 0; i < menuItems.length; i++) {
          menuItems[index].selectColor = '#EAF6FD'
      }
      setMenuItems([...menuItems])
    }
  }

  return (
    <>
    {
      menuItems.length > 0 && menuItems.map((item, index) => (
        <Link
          to={item.link}
          key={index}
          className="menuLink"
      >
          <div
              className="menuItem"
              key={index}
              onClick={() => { changeMenu(item, index) }}
              style={{ backgroundColor: item.selectColor }}
          >
              <div style={{ backgroundColor: item.selectColor }}></div>
              <div>{item.name}</div>
              <span style={{ height: '100%', display: 'flex', alignItems: 'center', position: 'absolute', right: '16px' }}><ChevronRightIcon style={{color:'#73b5e5'}}/></span>
          </div>
      </Link>
      ))
    }
    </>
  )
}

export default LeftMenu;