import './headerBar.scss';
import SubjectIcon from '@mui/icons-material/Subject';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Badge, Divider, List, ListItemButton, Collapse, Button   } from '@mui/material';
import { useState } from 'react';
import { getToken, clearToken } from "@/utils/token";
import { postAction } from "@/api/manage";
import { useSelector } from "react-redux";

function HeaderBar(props) {
  const {userName} = useSelector(state => state.user);
  const {
    changeMenuStatus,
    menuFlag,
  } = props

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open);
  }

  const logout = () => {
    postAction('/logout', {token: getToken()}).then(res => {
      clearToken();
      window.location.href = process.env.REACT_APP_MUTITENANT
    }).catch(err => {      
    })
  }
  
  return (
    <div className="headerBar">
      <div className="iconBlock" style={{ width: menuFlag ? '17%' : '0', paddingLeft: menuFlag ? '16px' : '0' }}>
        <img src={require('@/assets/icon-school.png')}/>
        <span>凸版進学塾</span>
      </div>
      <div className="menuBlock" style={{ width: menuFlag ? '83%' : '100%' }}>
        <div className="leftSelect">
          <div className="menuButton" onClick={changeMenuStatus}><SubjectIcon /></div>
        </div>
        <div className='rightSelect'>
          <Badge color="secondary" variant="dot" invisible={false} sx={{
            '& .MuiBadge-badge':{
              backgroundColor: '#ea4646'
            }
          }}>
            <NotificationsNoneOutlinedIcon />
          </Badge> 
          <Divider orientation="vertical" variant="middle" sx={{margin:'0 20px'}} />
          <List>
            <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative' }} onClick={handleClick}>
              <img src={require('@/assets/icon-school.png')}/>
              <span style={{color: '#333333',margin:'0 0 0 20px'}}>{userName}</span>
              <div style={{ display: "flex", justifyContent: 'center', position: 'relative', marginLeft: '40px' }}>
                {open ? <ExpandLess /> : <ExpandMore />}
              </div>              
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
              <List component="div" disablePadding>
                <Button
                    style={{position: 'absolute', right: 0, marginTop: '5px', padding: '13px', 
                            marginRight: '-15px', width: '244px', height: '48px', fontSize: '14px', 
                            display: 'flex', alignItems: 'center', justifyContent:'center',
                            backgroundColor: '#FFF', color:'black', boxShadow:'0px 0.12vw 0.85vh 0.12vw #DDD',
                            borderRadius: '4px'
                          }}
                    onClick={logout}
                  >ログアウト</Button>
              </List>
            </Collapse>
          </List>
        </div>
      </div>
    </div>
  )
}

export default HeaderBar;