
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';


function showLoading() {
    var dom = document.createElement('div')
    dom.setAttribute('id', 'loading')
    document.body.appendChild(dom)
    ReactDOM.createRoot(dom).render(
        <div style={{ textAlign: 'center' }}>
            <CircularProgress />
            <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
            <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
        </div>
    )
}


function hideLoading() {
    if (document.getElementById('loading') != null) {
        document.body.removeChild(document.getElementById('loading'))
    }    
}

export { showLoading, hideLoading };
