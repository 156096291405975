import { createBrowserRouter } from "react-router-dom";

import Login from "../page/Login";
import Layout from "@/page/Layout";
import Dashboard from "@/page/Dashboard";
import NotFound from "@/page/NotFound";
import SystemError from "@/page/SystemError";
import AccessError from "@/page/AccessError";
import AuthRoute from "./AuthRoute";
import Test from "@/page/Test"

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children:[
            {
                index: true,
                element: <Dashboard/>
            },
            {
                path: '/dashboard',
                element: <Dashboard/>
            },
            {
                path: '/test',
                element: <AuthRoute><Test/></AuthRoute>
            },
        ]
    },
    {
        path: '/system-err',
        element: <SystemError/>
    },
    {
        path: '/access-err',
        element: <AccessError/>
    },
    {
        path: '*',
        element: <NotFound/>
    }
])

export default router;