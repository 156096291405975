
import axios from "axios";
import ReactDOM from 'react-dom/client';
import { getToken } from "./token";
import { refreshToken } from "./cognito";
import System_error from "@/page/Dialog/System_error";
import { showLoading, hideLoading } from "./loading";

function showDialog(val) {
  var dom = document.createElement('div')
  dom.setAttribute('id', 'errorDialog')
  document.body.appendChild(dom)
  ReactDOM.createRoot(dom).render(
    <System_error msg={val}/>
  )
}
function hideDialog() {
  document.body.removeChild(document.getElementById('errorDialog'))
}

let apiBaseUrl = process.env.REACT_APP_POC_HOST;
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 100000 // 请求超时时间
})

var isRefreshing = false
var requests = []

// request interceptor
service.interceptors.request.use(config => {
  var notShowLoadingUrl = ['get-user-detail', 'find-student', 'find-navima', 'find-libry', 'find-exam-juku','get-navima-unitname','get-exam-unitname','get-libry-unitname'];
  const token = getToken();
  if (token) {
    config.headers[ 'authorization' ] = "Bearer" + ' ' + token // TODO 根据式样，配置如何传递token
  }
  if (!notShowLoadingUrl.includes(config.url)) {    
    showLoading();
  }  
  return config;
}, (error) => {
  hideLoading()
  return Promise.reject(error)
})

const err = (error) => {

  const { status, data, config } = error;
  // if (status === 401) { // token
  //   if (data.message == 'The incoming token has expired') {
  //     if (!isRefreshing) {
  //       isRefreshing = true
  //       return refreshToken()
  //         .then((sessionData) => {
  //           const accessToken = sessionData.data.idToken.jwtToken;
  //           localStorage.setItem(
  //             "access_token",
  //             accessToken
  //           );
  //           config.headers["Authorization"] = "Bearer " + accessToken;

  //           requests.forEach((cb) => cb(accessToken));
  //           requests = [];
  //           isRefreshing = false;
  //           return axios(config);
  //         })
  //         .catch((err) => {
  //           localStorage.setItem("access_token", "");
  //           localStorage.setItem("refresh_token", "");
  //           isRefreshing = false;
  //           requests = [];
  //         });
  //     } else {
  //       return new Promise((resolve) => {
  //         // put resolve in the queue, use a function to save it, and execute it directly when token is refreshed
  //         requests.push((token) => {
  //           // config.baseURL = ''
  //           config.headers.Authorization = token;
  //           resolve(axios(config))
  //         })
  //       })
  //     }
  //   } else {
  //     window.location.href = '/access-err'
  //   }
  // } else {
  //   // TODO 如果了解到后台返回的信息格式后，可以展示后台的错误信息
  //   showDialog()
  // }
  showDialog(data.errorMessage)
  
  return error.data;
}

service.interceptors.response.use((response) => {
  if (response.config.url.indexOf("get-disclosure-userids") == -1) {
    hideLoading()
  }  
  if (!response.status) {
    window.location.reload();
  }
  if (response.status === 200) {
    return response.data
  }
  return err(response)
}, (error) => {
  hideLoading()
  var notShowLoadingUrl = ['get-user-detail', 'find-student', 'find-navima', 'find-libry', 'find-exam-juku','get-navima-unitname','get-exam-unitname','get-libry-unitname'];
  if (error.code == 'ERR_NETWORK' && !notShowLoadingUrl.includes(error.config.url)) {
    if (document.getElementById('errorDialog') == null) {
      showDialog()
    }    
  }
  return err(error.response)
})

export default service;