import { getToken, setToken } from "@/utils/token";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const AuthRoute = ({children}) => {

  // useEffect(() =>{

  //   // const handleMessage = (event) => {
  //   //   // 处理接收到的消息
  //   //   console.log('Received message:', event.data);
  //   // };
  //   // window.addEventListener("message", handleMessage)
  //   // return () => {
  //   //   // 组件卸载前移除事件监听
  //   //   window.removeEventListener('message', handleMessage);
  //   // };


  // }, [])

  const isToken = getToken();
  if (isToken) {
    return <>{children}</>
  } else {
    return <Navigate to="/access-err" replace />
  }
}

export default AuthRoute