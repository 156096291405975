import { useDispatch } from "react-redux";
import { setSelectFlag } from "@/store/modules/userStore";
import { useEffect } from "react";
import { getToken } from "@/utils/token";

function Test() {

  const dispatch = useDispatch();
  useEffect(() =>{
    console.log("-------", getToken())
    dispatch(setSelectFlag('/test'))
  })

  return (
    <div>test</div>
  )
}

export default Test;