import { useState, useEffect } from "react";
import './index.scss';
import { InputBase, Select, MenuItem, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,Pagination } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import IconNav from '@/assets/iconNav.png'
import IconNavSelect from '@/assets/iconNav-select.png'
import IconLib from '@/assets/widgit_lib.png'
import IconLibSelect from '@/assets/widgit_lib_select.png'
import Article from '@/assets/article.png'
import ArticleSelect from '@/assets/article-select.png' 
import { useDispatch } from "react-redux";
import { setSelectFlag, setUserName } from "@/store/modules/userStore";
import { setToken, setRefreshToken } from "@/utils/token";
import { getAction, postAction } from "@/api/manage";
import classNames from "classnames";
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';

var students = [];
var _nameMap = {};
var _schoolMap = {};
var _points = [];
var subjects = ['japanese', 'math', 'science', 'social', 'english'];

function Dashboard() {

  const dispatch = useDispatch();

  

  // --- 画面初期化 ---
  useEffect(() => {  
    if (window.opener != null) {
      console.log("===window opener==1=");
      var isLoaded = false;
      window.addEventListener('message', ({ data, origin, srouce }) => {            
        // muti-tenent　から  
        if (isLoaded) {
          return;
        }
        if (data.type === 'message' && origin == process.env.REACT_APP_MUTITENANT) {
          isLoaded = true;
          console.log("===window opener==2=", data, origin);
          setToken(data.data.access_token);
          setRefreshToken(data.data.refresh_token)
          getUserName(data.data.access_token)
          // 学生一覧取得 
          getStudents(() => {
            initColumnTitle(2, 0, (units) => {
              getList(0, 2, units)
            });            
          });
        } 
      })
      console.log("===window opener==3=");
      window.opener && window.opener.postMessage('load-success', '*')
      //window.opener = null;
    } else {
      window.location.href = process.env.REACT_APP_MUTITENANT
      // initColumnTitle(0);
      // getList(0, 0)
    }
    
    // if (window.opener == null) { // other
    //   initColumnTitle(0);
    //   getList(0)
    // }

    dispatch(setSelectFlag('/dashboard'))
  }, [])

  // ---- 検索部分 ---
  const [studentName, setStudentName] = useState("");
  const [school, setSchool] = useState("");
  const [correctPer, setCorrectPer] = useState("");
  const handleCorrectChange = (event) => {
    setCorrectPer(event.target.value);
  }
  const handleSearch = () => {
    getList(subject, tabIndex, null);
  }

  const [columnTitle, setColumnTitle] = useState([]); // table header
  const [rows, setRows] = useState([]);
  const [tabIndex, setTabSelect] = useState(2); // 0-lib 1-小学校　2-nav
  const [subject, setSubject] = useState(0); // 0-国語 1-算数 2-理科 3-社会 4-英語
  const changeTabIndex = (val) => {
    setTabSelect(val);
    setRows([]);
    initColumnTitle(val, subject, (units) => {
      getList(subject, val, units);
    });    
  }
  const changeSubjectIndex = (val) => {
    setSubject(val);
    initColumnTitle(tabIndex, val, (units) => {
      getList(val, tabIndex, units);
    });    
  }

  const getStudents = (callback) => {
    postAction("get-disclosure-userids", {}).then(res => {
      if (res.users && res.users.length > 0) {
        students = res.users;
      }      
      var promises = [];
      res.users.forEach(item => {
        // 名前
        var p =postAction("get-user-detail", {userid: item}).then(userRes => {
          if (userRes && userRes.name) {
            _nameMap[item] = userRes.name;
            setNameMap({..._nameMap})            
          }
        }).catch(e=>{})
        promises.push(p)
        // 学校
        var sp = postAction("find-student", {userId: item}).then(schoolRes => {
          if (schoolRes && schoolRes.school_name) {
            _schoolMap[item] = schoolRes.school_name;
            setSchoolMap({..._schoolMap})
          }
        }).catch(e=>{})
        promises.push(sp)
      })
      Promise.all(promises).then(res => {
        if (callback != null) {
          callback();
        }
      }).catch(e => {})
    }).catch(e =>{
      setRows([]);
    })
  }

  const initColumnTitle = (val, sub, callback) => {
    let url = "";
    if (val == 0) {
      url = "get-libry-unitname"
    } else if (val == 1) {
      url = "get-exam-unitname"
    } else {
      url = "get-navima-unitname"
    }
    postAction(url, {subject: subjects[sub]}).then(res => {
      var unitNames = [
        {name:'生徒氏名', width: '96px', textAlign: 'center'}, 
        {name:'学校', width: '128px', textAlign: 'center'}, 
      ]
      if (res.units && res.units.length > 0) {
        res.units.forEach(item => {
          unitNames.push({
            name:item, width: '80px', textAlign: 'center'
          });
        })
      }
      setColumnTitle(unitNames);
      if (callback != null) {
        callback(res.units);
      }
    }).catch(e=>{})
  }
  const getList = async (subject, lib, units) => {
    if (units == undefined) { // 検索(frontendで検索を行う)
      var filterData = _points.filter(data => {
        if (!isNull(studentName.trim())) {
          if (data[1] == undefined || data[1] == null) {
            return false;
          } else if (data[1].indexOf(studentName) == -1) {
            return false;
          }
        }
        if (!isNull(school.trim())) {
          if (data[2] == undefined || data[2] == null) {
            return false;
          } else if (data[2].indexOf(school) == -1) {
            return false
          }
        }
        if (lib == 2) { // NAV場合　小学校のみ表示          
          if (!hasPoints(data)) {
            return false;
          }
          if (data[2] == undefined) { // 学校名ない場合、非表示
            return false;
          }
          if (data[2].indexOf("小学校") != -1) {
            return true;
          } else {
            return false;
          }
        } else if (lib == 0) { // LIb 中校　高校表示
          if (!hasPoints(data)) {
            return false;
          }
          if (data[2] == undefined) { // 学校名ない場合、非表示
            return false;
          }
          if (data[2].indexOf("中学校") != -1 || data[2].indexOf("高校") != -1) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      setRows(filterData)
    } else {
      var initPoints = [];
      var unitsPoints = [];
      for (var i=0; i<units.length; i++) {
        unitsPoints.push('');
      }
      students.forEach((item) => {
        var pointItem = [item, _nameMap[item], _schoolMap[item]];
        pointItem.push(...unitsPoints);
        initPoints.push(pointItem);
      })
      _points = initPoints;
      //setRows(initPoints);

      var url = "";
      if (lib == 0) {
        url = "find-libry";
      } else if (lib == 1) {
        url = "find-exam-juku";
        subject = 1;
      } else if (lib == 2) {
        url = "find-navima"
      }
      
      for (var i=0; i<students.length; i++) {
        var item = students[i];
        var idx = i;
        // NAV場合　小学校データ取得
        if (lib == 2) {
          if (!_points[idx][2]) {
            continue;
          }
          if (_points[idx][2].indexOf("小学校") == -1) {
            continue;
          } 
        } else if (lib == 0) { // LIB場合　中学校　高校データ取得
          if (!_points[idx][2]) {
            continue;
          }
          if (_points[idx][2].indexOf("中学校") == -1 && _points[idx][2].indexOf("高校") == -1) {
            continue;
          } 
        }
        console.log("===s==", idx)
        var res = await postAction(url, {userId: item, subject: subjects[subject]});

        console.log("===res==", idx)
        if (res && res.length > 0) {
          res.forEach((points) => {
            var unitIdx = units.indexOf(points.unit);
            if (lib == 2) { // NAV
              unitIdx = units.indexOf(points.unit + points.small_unit);
            }
            if (lib == 0) { // LIB
              unitIdx = units.indexOf(points.subject + points.unit);
            }
            if (unitIdx != -1) {                
              if (lib == 0 || lib == 2) {
                _points[idx][unitIdx + 3] = points.question_count && points.question_right ?  (points.question_right*100/points.question_count).toFixed(0) : 0
              } else {
                _points[idx][unitIdx + 3] = points.exam_mark + "---" + points.exam_pdf
              }
            }
          })
        }

        var filterData = _points.filter(data => {
          if (!isNull(studentName.trim())) {
            if (data[1] == undefined || data[1] == null) {
              return false;
            } else if (data[1].indexOf(studentName) == -1) {
              return false;
            }
          }
          if (!isNull(school.trim())) {
            if (data[2] == undefined || data[2] == null) {
              return false;
            } else if (data[2].indexOf(school) == -1) {
              return false
            }
          }
          if (lib == 2) { // NAV場合　小学校のみ表示
            if (!hasPoints(data)) {
              return false;
            }
            if (data[2] == undefined) { // 学校名ない場合、非表示
              return false;
            }
            if (data[2].indexOf("小学校") != -1) {
              return true;
            } else {
              return false;
            }
          } else if (lib == 0) { // LIb 中校　高校表示
            if (!hasPoints(data)) {
              return false;
            }
            if (data[2] == undefined) { // 学校名ない場合、非表示
              return false;
            }
            if (data[2].indexOf("中学校") != -1 || data[2].indexOf("高校") != -1) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        })
        setRows(filterData)

        console.log("==e===", idx, _points)
      }
    }
  }

  const hasPoints = (data) => {
    if (data == null || data == undefined || data == "") {
      return false;
    }
    var hasFlag = false;
    data.forEach((item, idx) => {
      if (idx > 2) {
        if (item != "") {
          hasFlag = true;
        }
      }
    })
    return hasFlag;
  }
  const isNull = (val) => {
    if (val == undefined || val == null || val == "") {
      return true;
    }
    return false;
  }
  const cellColor = (val) => {
    if (tabIndex == 1) { // 小学校の場合
      return "tableCellWhite"
    }
    if (val == "" || isNaN(val)) {
      return "tableCellWhite"
    }
    if (val <= 10) {
      return 'tableCellPink'
    }
    if (val <= 25) {
      return 'tableCellYellow'
    }
    if (val <= 50) {
      return 'tableCellWhite'
    }
    return 'tableCellGreen'
  }

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const getUserName = (token) => {
    postAction('/inspection', {token}).then(res => {      
      if (res.active) {
        let userId = res.sub
        postAction('/get-user-detail', {userid: userId}).then(res => {
          if (res.name) {
            dispatch(setUserName(res.name))
          }
        }).catch(e=>{})
      }
    }).catch(e=>{})
  }

  const getItemInfo = (item) => {
    if (item == undefined || item == null || item == "") {
      return item
    }
    var index = item.indexOf("---")
    if (index == -1) {
      return item
    } else {
      return <a href={item.substr(index + 3)} style={{textDecoration:'none', color:'#333333', cursor:'pointer'}}>{item.substr(0, index)}</a>
    }
  }

  return (
    <>
      <div className="nav_name">ホーム</div>
      <div className="ground"> 
        <div className="nav_title">ホーム</div>
        <div className="searchArea">
          <div className="searchAreaLabel">生徒氏名</div>
          <InputBase type="text" placeholder="生徒名" className="input" value={studentName} onChange={(e) => {setStudentName(e.target.value)}}></InputBase>
          <div className="searchAreaLabel">学校</div>
          <InputBase type="text" placeholder="学校" className="input" sx={{width:'184px !important'}} value={school} onChange={(e) => {setSchool(e.target.value)}}></InputBase>
          <div className="searchAreaLabel">正答率</div>
          <Select
            className="select"
            displayEmpty={true}
            value={correctPer}
            onChange={handleCorrectChange}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline':{
                border: 'none'
              }
            }}
            renderValue={(value) => {
              if (value) {
                return value;
              }
              return <span className="placeholder">正答率</span>;
            }}
          >
            <MenuItem value="" className="menuItem">&nbsp;</MenuItem>
            <MenuItem value={10} className="menuItem">10</MenuItem>
            <MenuItem value={20} className="menuItem">20</MenuItem>
            <MenuItem value={30} className="menuItem">30</MenuItem>
          </Select>
          <Button variant="contained" endIcon={<SearchIcon />} className="button" onClick={handleSearch}>検索</Button>
        </div>

        <div className="contentArea">
          {/* <div className="subject_tabs">
            <div className="subject_tab" style={{backgroundColor: subject == 0 ? '#E64363' : ''}} onClick={() => {changeSubjectIndex(0)}}>
              <span>国語</span>
            </div>
            <div className="subject_tab" style={{backgroundColor: subject == 1 ? '#4474ED' : ''}} onClick={() => {changeSubjectIndex(1)}}>
              <span>数学</span>
            </div>
            <div className="subject_tab" style={{backgroundColor: subject == 2 ? '#00A99D' : ''}} onClick={() => {changeSubjectIndex(2)}}>
              <span>理科</span>
            </div>
            <div className="subject_tab" style={{backgroundColor: subject == 3 ? '#E8B500' : ''}} onClick={() => {changeSubjectIndex(3)}}>
              <span>社会</span>
            </div>
            <div className="subject_tab" style={{backgroundColor: subject == 4 ? '#7400E8' : ''}} onClick={() => {changeSubjectIndex(4)}}>
              <span>英語</span>
            </div>
          </div> */}
          <div className="tabs">
            <div className={`tab ${tabIndex == 2 ? 'tabSelect' : ''}`} onClick={() => {changeTabIndex(2)}}>
              <img src={tabIndex == 2 ? IconNavSelect : IconNav} />
            </div>
            <div className={`tab ${tabIndex == 0 ? 'tabSelect' : ''}`} onClick={() => {changeTabIndex(0)}}>
              <img src={tabIndex == 0 ? IconLibSelect : IconLib} />
            </div>
            <div className={`tab ${tabIndex == 1 ? 'tabSelect' : ''} tabRight`} onClick={() => {changeTabIndex(1)}}>
              <img src={tabIndex == 1 ? ArticleSelect : Article} />
              <span>テスト結果</span>
            </div>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
                <TableRow>
                  {
                    columnTitle.map((item, idx) => (
                      <TableCell key={idx} align="center" className="tableHeaderCell" style={{width:item.width, textAlign:item.textAlign, minWidth: '100px'}}>{item.name}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 && rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {
                      row.map((item, idx) => (
                        idx == 0 ? '' : <TableCell key={idx} align="center" className={classNames('tableBodyCell', cellColor(item))} style={{borderBottomColor: (idx == 1 || idx == 2 || item == '' || tabIndex == 1 || (item > 25 && item <= 50)) ? '#DDDDDD' : '#FFFFFF'}}>
                        {(idx ==1 || idx == 2 || tabIndex == '1' || item == '' ? '' : '正答率 ')}
                        {getItemInfo(item)}
                        {(idx ==1 || idx == 2 || tabIndex == '1' || item == '' ? '' : '% ')}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))}
                {rows.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={columnTitle.length}>
                      <div style={{width: '100%', textAlign: 'center'}}>
                        <div><TopicOutlinedIcon /></div>
                        <div>No Data</div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}          
              </TableBody>
          </Table>
        </TableContainer>        
        {/* <Pagination page={page} count={10} color="primary" onChange={handlePageChange}/> */}
      </div>
    </>
  )
}

export default Dashboard;