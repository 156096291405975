const TOKENKEY = "pc_access_token";
const TOKENREFRESHKEY = "pc_access_refresh_token";

function setToken(token){
  return sessionStorage.setItem(TOKENKEY, token)
}
function setRefreshToken(token){
  return sessionStorage.setItem(TOKENREFRESHKEY, token)
}


function getToken(){
  return sessionStorage.getItem(TOKENKEY);
}
function getRefreshToken(){
  return sessionStorage.getItem(TOKENREFRESHKEY);
}

function clearToken(){
  sessionStorage.removeItem(TOKENKEY);
  sessionStorage.removeItem(TOKENREFRESHKEY);
  return true;
}

export {setToken, setRefreshToken, getToken, getRefreshToken, clearToken}