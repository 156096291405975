import { useState } from 'react';
import './System_error.scss';
import { Dialog, Modal,Alert } from '@mui/material';

function System_error(props) {

  const {msg} = props;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const editMsg = () => {
    if (msg) {
      return msg;
    } else {
      return (
        <div>
          サーバーに接続できません。<br/>マルチテナントにアクセスできません。<br />インターネット接続をご確認ください。
        </div>
      );
    }    
  }

  return (
    // <Modal
    //     open={true}
    //     className={classes.noneButton}
    //     centered
    //     width={VW(800)}
    //     closable={false}
    // >
    //     <div className={classes.groundBlock}>
    //         <System_warning />
    //         <div className={classes.contentBlock}>
    //             サーバーに接続できません。<br />
    //             マルチテナントにアクセスできません。<br />
    //             インターネット接続をご確認ください。
    //         </div>
    //         <Button className={classes.okButton} onClick={goLogin}>OK</Button>
    //     </div>
    // </Modal >

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Alert severity="error">          
          {editMsg()}
        </Alert>
    </Dialog>
  )
}

export default System_error;